<template>
  <div>
    Hello
  </div>
</template>
<style lang="scss" scoped>
@import './index.scss';
</style>
<script>
export default {
  name: 'Hello',
  data () {
    return {}
  },
  components: {},
  created () {
    if (process.env.NODE_ENV === 'production') {
      window.location.href = 'http://www.i200.cn/'
    }
  },
  mounted () {

  },
  beforeDestroy () {
  },
  methods: {},
  computed: {},
  watch: {}
}
</script>
